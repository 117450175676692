import "./Landing.scss";

import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPage } from "../../actions";
import { Helmet } from "react-helmet";
import Content from "../content/Content";
import HomeSlider from "../slider/HomeSlider";
import VideoDetail from "../video/VideoDetail";
import Wines from "../wines/Wines";
import Vib from "../vib/Vib";
import Gallery from "../gallery/Gallery";
import Recipes from "../recipes/Recipes";
import Button from "../buttons/Button";
import SocialBanner from "../social-feed/SocialBanner";
import Contact from "../forms/Contact";

class LandingPage extends Component {
  componentDidMount() {
    this.props.fetchPage("home");
  }

  renderHeader(){
    return <Content disableFlex fullWidth centered item={{ 
      title: "WHY FAT <i>bastard</i>?", 
      subtitle: '<p>When <span style="color: #d6b052;"><strong>Thierry and Guy</strong></span> first created these delicious wines, they did it with gusto. They poured their hearts, souls and great dollops of generosity into every bottle. In fact, it was a great big discovery (in winemaking terms) that led to the name <span style="color: #d6b052;"><strong>&lsquo;FAT <em>bastard</em>.&rsquo;</strong></span>&#xB4;<br>It comes from having left the wine on the lees for a lot longer than usual, which allowed it to develop more fully and completely. And when tasted, there was simply no other way to describe it other than: <strong><span style="color: #d6b052;">&ldquo;<em>Well, now that&rsquo;s a</em> FAT <em>bastard</em>!&rdquo;</span></strong></p><p>This tradition of creating full-bodied wines to be enjoyed with bellyfuls of laughs and good-natured cheer continues today. Our winemakers give nothing short of their all when meticulously nurturing them to perfection. Whether it&rsquo;s a celebration, a kuier or a <strong><span style="color: #d6b052;">&ldquo;<em>COME ON OVER!&rdquo;</em></span></strong>, there&rsquo;s a FAT bastard (or two) waiting to be opened.</p>'
    }} />
  }

  render() {
    if (!this.props.isVerified) {
      return <div>loading</div>;
    }

    return (
      <React.Fragment>
         <Helmet>
          <meta charSet="utf-8" />
          <title>FAT bastard wines | Home</title>
          <meta name="description" content="Full-bodied wines to be enjoyed with bellyfuls of laughs and good-natured cheer. Whatever the celebration there’s a FATbastard(or two) waiting tobe opened." />
          <link rel="canonical" href={window.location} />
        </Helmet>
        <div
          style={{
            marginBottom: "120px",
            // height: "370px",
            background: "#D6B052"
          }}
        >
        <HomeSlider />
        </div>
        <div className="ui container">
          {this.renderHeader()}
        </div>
        {/*<div className="ui container">
          <Content item={this.props.page} animated showHeader />
        </div>*/}

        <div className="container-small">
          <VideoDetail videoId={1} />
        </div>

        <div className="ui container">
          <Wines amount={window.innerWidth < 1025 ? 4 : 3} />
          <div id="vib"></div>
          <Button
            toUrl="/the-fat-bastards"
            color="purple"
            title="MORE WINES"
            centered
          />


          <Vib showHeader />
          <br /><br />

        </div>

        <Gallery pageId={1} />

        <div className="ui container">
          <Recipes showHeader={true} amount={4} centered={false} />
          <Button
            toUrl="/fat-inspirations"
            color="purple"
            title="MORE RECIPES"
            centered
          />
        </div>

        <SocialBanner />
        <Contact />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    page: state.page,
    isVerified: state.isVerified
  };
};

export default connect(
  mapStateToProps,
  {
    fetchPage
  }
)(LandingPage);
